<template>
  <div class="caredokter-container-cancel text-center">
    <div>
      <img :src="icon[type]" class="d-block m-auto image-status" />
      <label class="label-cancel-header">
        {{ status.header }}
      </label>
      <!-- <img src="@/assets/img/ornamen-cancel.jpg" class="ornamen-cancel" v-if="status.ornamen"/> -->
      <div class="detail-payment-desc">
        <div class="ornamen">
          <span :class="$route.name">//////////</span>
        </div>
        <p
          class="text-font-2"
          v-for="(item, index) in status.description"
          :key="index"
        >
          <span v-html="item"></span>
        </p>
      </div>

      <div
        class="position-relative success-payment-method"
        v-if="statusPayment == 'PAID'"
      >
        <img
          class="position-absolute"
          :src="setImage(detailMethod.paymentMethod.icon)"
          alt=""
          width="45"
          @error="$event.target.src = defaultImage"
        />
        <label class="text-font-3">{{ detailMethod.paymentMethod.name }}</label>
      </div>
    </div>
  </div>
</template>
<script>
import IconCancelPayment from "@/assets/img/cancel-payment.png";
import IconSuccessPayment from "@/assets/img/success-payment.png";
import IconExpiredPayment from "@/assets/img/expired-payment.png";
import noImage from "@/assets/no-payment.png";
import { mapState } from "vuex";
import StaticText from "@/helpers/StaticText";

export default {
  computed: {
    ...mapState({
      statusPayment: (state) => state.statusPayment,
      detailMethod: (state) => state.detailMethodPayment,
    }),
  },
  data() {
    return {
      defaultImage: noImage,
      icon: {
        cancel: IconCancelPayment,
        success: IconSuccessPayment,
        expired: IconExpiredPayment,
      },
      type: "",
      status: {
        header: "",
        ornamen: "",
        description: [],
      },
    };
  },
  mounted() {
    if (this.$route.name == "cancel-payment") {
      this.type = "cancel";
      this.status.header = StaticText().cancelHeader;
      this.status.ornamen = "1";
      this.status.description = [
        StaticText().descriptionStatus1,
        StaticText().descriptionStatus2,
      ];
    } else if (this.$route.name == "success-payment") {
      this.type = "success";
      this.status.header = StaticText().successHeader;
      this.status.description = [
        `${StaticText().descSuccess} <strong>${
          this.detailMethod.paymentMethod.name
        }</strong>`,
      ];
    } else if (this.$route.name == "expired-payment") {
      this.type = "expired";
      this.status.header = StaticText().expiredHeader;
      this.status.ornamen = "1";
      this.status.description = [
        StaticText().descriptionStatus1,
        StaticText().descriptionStatus2,
      ];
    }
  },
  methods: {
    setImage(img) {
      return "data:image/svg+xml;base64," + img;
    },
  },
};
</script>
